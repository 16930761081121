import * as React from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"

import Popout from "./city-select";
import {useFenixContext} from '../helpers/context';
import {useCityList} from "../hooks/use-city-list";

export default function TopCities(props: any) {
  const {state, dispatch} = useFenixContext();
  return (
    <div className="top-cities" ref={props.divRef}>
      <div className="top-cities__container">
        <div className="top-cities__header">
          {props.hubData.slice(0, 3).map((item: any, i: any) => {
            return (
              <div key={i} className="city-card text-center">
                <GatsbyImage className={`city-card__image`} alt={item.title ? item.title : ''} loading="lazy"
                             image={item.image?.middle?.localFile?.childImageSharp?.gatsbyImageData}/>
                <div className="city-card__content">
                  {item.title && item.cityUrl ?
                    <Link className="text-white" to={"/funeral-directors/" + item?.cityUrl + "/"}>
                      <h2>{item.title === "Greater London" ? "London" : item.title === "Greater Manchester" ? "Manchester" : item.title === "Birmingham (West Midlands)" ? "Birmingham" : item.title}</h2>
                    </Link> : ''}
                </div>
                <div
                  className="city-card__row d-flex flex-row flex-sm-column flex-lg-row justify-content-between justify-content-sm-around justify-content-xl-between">
                  {item.title && item.cityUrl ?
                    <Link className="city-card__link" to={"/funeral-directors/" + item.cityUrl + "/"}
                          title="Select">Select</Link> : ''}
                  <button onClick={() => dispatch({type: "modalBooking"})} id="sthBtn" type="button"
                          className="btn btn-transparent gtm-strongintent" data-toggle="modal"
                          data-target="#bookMeetingForm">
                    <span>Book consultation</span>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="top-cities__footer">
          <GatsbyImage
            className={`city-card__image`}
            image={props.topCitiesBg[0]?.node?.childImageSharp?.gatsbyImageData} alt="background-image-map"
            loading="lazy"/>
          <div className="top-cities__selector">
            <h3 className="top-cities__title text-center text-deepblue">More areas:</h3>
            <div id="top-dropdown" className="bg-white">
              <div id="top-dropdown-list" className="transparent-city__choice">
                <Popout
                  cities={useCityList()}
                  id={'topbarSelectCitiesTop'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import * as React from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"
import CleanDataParser from "../helpers/editorjs-parser";

export default function FenixWebLawyer(props: any) {

  const pageTextOurServices = (
    <div>
      {props.textOurServices?.data?.textOurServices ? (
        JSON.parse(props.textOurServices?.data?.textOurServices).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const pageTextAboutFenix = (
    <div>
      {props.textAboutFenix?.data?.textAboutFenix ? (
        JSON.parse(props.textAboutFenix?.data?.textAboutFenix).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  return (
    <div className="lawyer-section bg-deepblue pl-4 pr-4">
      <div className="dual-card dual-card--lawyer row no-gutters flex-column-reverse flex-lg-row reverse-row">
        <div
          className="dual-card__half col-12 col-lg-6 col-top-left dual-card__block bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5">
          <h2 className="dual-card__title text-deepblue">{props.titleAboutFenix}</h2>
          <div className="dual-card__content">{pageTextAboutFenix}</div>
          <u className="mt-5 d-inline-block"><Link to={props.buttonLinkAboutFenix} className="text-darkblue"
                                                   title={props.buttonTextAboutFenix}>{props.buttonTextAboutFenix}</Link></u>
        </div>
        <div className="dual-card__half col-12 col-lg-6 col-bottom-right dual-card__lawyer">
          <Link to={"/about-fenix/"}>
            <div className="lawyer-bg">
              <div className="lawyer-bg__image">
                <GatsbyImage className={`city-card__image`} image={props.imageAbout} alt="background-image-map"
                             loading="lazy"/>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="quote-container quote-container--sign content-container">
        <div className="quote-container__bg1"></div>
        <div className="quote-wrapper quote-wrapper--sign">
          <p className="text-lightyellow">{props.quoteText}<br/><span>{props.quoteAuthor}</span></p>
        </div>
        <img className="quote-line" src="/images/line_quote.svg" alt="Fenix Funeral Directors"/>
        <img className="quote-line-mob" src="/images/line_quote-mob.svg" alt="Fenix Funeral Directors"/>
      </div>
      {props.packageGrid}
      <div className="quote-container quote-container--title content-container">
        <div className="quote-container__bg2"></div>
        <div className="quote-wrapper quote-wrapper--title">
          <h2 className="text-white">{props.titleTransparency}</h2>
          <p className="text-white">{props.textTransparency}</p>
        </div>
      </div>
      <div className="dual-card dual-card--video row no-gutters flex-column flex-lg-row">
        <div className="col-12 col-lg-6 dual-card__half col-top-left" style={stylesInline.overflowHidden}>
          <div className="video-bg">
            <div className="video-bg__image">
              <GatsbyImage className={`city-card__image`} image={props.imageService} alt="background-image-map"
                           loading="lazy"/>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-lg-6 dual-card__half col-bottom-right dual-card__block bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5">
          <h2 className="dual-card__title text-deepblue">{props.titleOurServices}</h2>
          <div className="dual-card__content">{pageTextOurServices}</div>
          <u className="mt-5 d-inline-block"><a title={props.buttonTextOurServices} className="text-darkblue"
                                                href={props.buttonLinkOurServices}>{props.buttonTextOurServices}</a></u>
        </div>
      </div>
      {props.reviews}
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  overflowHidden: {
    overflow: "hidden",
  }
};